@import "./assets/design/css-variables.css";

.no-show-content {
    visibility: hidden;
}

.width-100 {
    width: 100%;
}

::placeholder {
    color: var(--very-dark-gray);
}

.header {
    background-color: var(--edata-official);
    margin-bottom: 24px;
}

.header-content {
    background-color: transparent;
}

.header-content > .row-left {
    padding-left: 0;
}

.header-content > .row-right {
    text-align: end;
    padding-right: 0;
}

.calendar-with-buttons {
    background-color: transparent;
    border: 1px solid transparent;
    border-top: 0;
}

div.calendar-with-buttons > button,
.header-content > .row-right > button {
    background-color: var(--edata-official);
    border: 1px solid var(--edata-official);
    border-radius: 0;
}

div.calendar-with-buttons > button:hover,
.header-content > .row-right > button:hover {
    background-color: var(--edata-official-hover) !important;
    border: 1px solid transparent !important;
}

div.calendar-with-buttons > button:focus,
.header-content > .row-right > button:focus {
    border-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.worklog-calendar > input {
    text-align: center;
}

.today-tooltip {
    background-color: var(--edata-official) !important;
    border: 1px solid transparent !important;
}

.today-tooltip.p-tooltip .p-tooltip-arrow {
    background: transparent !important;
    border-right-color: var(--light-gray);
}

.today-tooltip.p-tooltip .p-tooltip-text {
    background-color: var(--light-gray) !important;
    color: var(--edata-official) !important;
}

.login {
    height: 100vh;
    width: 100%;
    text-align: center;
    background-color: var(--very-dark-desaturated-blue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-card {
    width: 560px;
    border-radius: 0;
}

.login-card > .p-card-header {
    background-color: var(--edata-official);
    padding: 25px 100px;
}

.login-card > .p-card-body {
    padding: 75px 90px 35px 90px;
}

.login-inputs-div {
    margin-bottom: 48px;
}

.login-submit-button-div {
    padding: 0 125px;
}

.login-submit-button {
    background-color: var(--edata-official);
    border: 1px solid var(--edata-official);
    color: var(--white);
    border-radius: 0;
    width: 100%;
}

.login-submit-button:hover {
    background-color: var(--edata-official-hover) !important;
    border: 1px solid transparent !important;
    color: var(--white);
}

.login-card > .p-card-body > .p-card-footer {
    display: none !important;
}

.logout {
    margin-left: auto;
}

.worklog-panel-labels {
    text-align: center;
    color: var(--very-dark-gray);
}

.worklog-row .action-buttons > button {
    margin: auto;
    width: 30px;
    height: 30px !important;
}

.worklog-row.has-been-sent {
    background: var(--green-100);
}

.worklog-row.overlap {
    background: var(--overlap);
}

.worklog-row.has-errors {
    background: var(--errors);
}

.worklog-row.has-errors .action-buttons > button,
.worklog-row.has-errors .time-spent-div {
    color: var(--white);
}

.worklog-row.has-been-sent > div > input,
.worklog-row.has-errors > div > input,
.worklog-row.has-been-sent > div > span > input,
.worklog-row.has-errors > div > span > input {
    border: transparent;
}

.p-inputtext {
    width: 100% !important;
}

.p-calendar-timeonly {
    width: 100% !important;
}

.input-time > input {
    text-align: center;
}

.reminder {
    margin-top: 24px;
}

.p-button {
    transition: none !important;
}

.p-toggleable-content {
    transition: none !important;
}

.p-accordion-header-link:focus {
    border-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.p-accordion-header a {
    text-decoration: none;
}

.p-accordion .card {
    border: none;
}

.p-accordion-content .card table > thead {
    display: none;
}

.p-progressbar-determinate > .p-progressbar-value-animate {
    transition: width 0s ease-in-out !important;
}

.p-inline-message {
    width: 100%;
    justify-content: left;
}

.p-button.p-component.p-confirm-popup-reject.p-button-sm.p-button-text {
    color: var(--very-dark-gray) !important;
}

.p-button.p-component.p-confirm-popup-reject.p-button-sm:hover {
    background-color: var(--light-gray) !important;
    border: 1px solid transparent !important;
}

.p-button.p-component.p-confirm-popup-accept.p-button-sm {
    background-color: var(--edata-official) !important;
    border: 1px solid var(--edata-official) !important;
}

.p-button.p-component.p-confirm-popup-accept.p-button-sm:hover {
    background-color: var(--edata-official-hover) !important;
    border: 1px solid transparent !important;
}

.p-button.p-component.p-confirm-popup-accept.p-button-sm:focus {
    border-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.time-spent-div {
    height: 50px;
    padding: 12px;
}

.plus-icon {
    font-weight: 800;
}

.pi-check {
    font-weight: bold;
}

.send-button {
    width: 100%;
    text-align: end;
    justify-content: center;
    background-color: #efefef;
    border: 1px solid #efefef;
    color: var(--edata-official);
    font-weight: bold;
}

.send-button:hover {
    background-color: var(--edata-official) !important;
    border: 1px solid transparent !important;
    text-decoration: none !important;
}

.send-button:focus {
    border-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.mid-screen > .row:first-child {
    margin-bottom: 15px;
}

.small-screen .calendar {
    margin-left: 0;
    margin-right: 0;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .logout-text {
        display: none;
    }

    .row-right,
    .calendar-with-buttons {
        padding-right: 0;
    }

    .small-screen > div.row:nth-child(2) {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .mid-screen {
        display: none;
    }

    .header-content > .row-left.no-show-content,
    .today-button {
        display: none;
    }

    .calendar-with-buttons {
        text-align: center;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1675px) {
    .small-screen {
        display: none;
    }

    .mid-screen > .header-content {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 991px) {
    .worklog-panel-labels,
    .large-screen-worklog {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .mid-screen-worklog {
        display: none;
    }

    .input-description::placeholder,
    .autocomplete-issue > input::placeholder {
        color: transparent;
    }
}

@media only screen and (min-width: 1675px) {
    .small-screen {
        display: none;
    }

    .input-description::placeholder,
    .autocomplete-issue > input::placeholder {
        color: transparent;
    }

    .mid-screen > .body {
        margin: 0 15px 0 15px;
    }
}
