:root {
    /* BREAKS */

    /* COLORS */
    --dark-blue: #1e60a6;
    --edata-official: #a6192e;
    --edata-official-hover: #781221;
    --errors: #a85d67;
    --light-gray: #c0c0c0;
    --moderate-blue: #337ab7;
    --overlap: #f6e8ea;
    --strong-blue: #2578cf;
    --very-dark-desaturated-blue: #243746;
    --very-dark-gray: #707070;
    --white: #ffffff;
}
